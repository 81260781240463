body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  color: rgb(233, 233, 233);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #40E0D0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

h1 {
  font-family: 'Fugaz One', cursive;
  font-size: 5em;
}

input {
  color: rgb(233, 233, 233);
  border-color: rgb(212, 212, 212);
}

input::placeholder {
  color: rgba(233, 233, 233, 0.856);
}

input:focus {
  outline-style: none;
}

nav {
  padding: 0 2em;
}