@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');

.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}
