@import url(https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap);
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  color: rgb(233, 233, 233);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #40E0D0;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

h1 {
  font-family: 'Fugaz One', cursive;
  font-size: 5em;
}

input {
  color: rgb(233, 233, 233);
  border-color: rgb(212, 212, 212);
}

input::-webkit-input-placeholder {
  color: rgba(233, 233, 233, 0.856);
}

input:-ms-input-placeholder {
  color: rgba(233, 233, 233, 0.856);
}

input::placeholder {
  color: rgba(233, 233, 233, 0.856);
}

input:focus {
  outline-style: none;
}

nav {
  padding: 0 2em;
}
.App {
  text-align: center;
}

.center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.image-carousel {
    object-fit: cover;
    width: 700px;
    height: 600px;
    box-shadow: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 );
}

.share {
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadeInDelayed;
            animation: 1.5s ease 0s normal forwards 1 fadeInDelayed;
}

.results-container {
    display: table;
    -webkit-animation: 1s ease 0s normal forwards 1 fadeIn;
            animation: 1s ease 0s normal forwards 1 fadeIn;
}

@media (max-width: 800px) {
    .results-container {
        display: inline;
    }
}

.portrait-crop{
    height: 500px;
    width: 400px;
}

.portrait-crop img{
    object-fit: cover;
    object-position: 50% 0%;
}

.loading-dots > div{
    width: 0.4rem;
    height: 0.4rem;
    background-color: rgb(233, 233, 233);
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: 1.4s bounce infinite ease-in-out both;
            animation: 1.4s bounce infinite ease-in-out both;
    margin: 0 1.5px;
}

.loading-dots .bounce1 {
    -webkit-animation-delay: -0.30s;
            animation-delay: -0.30s;
}

.loading-dots .bounce2 {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@-webkit-keyframes bounce {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes bounce {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes fadeInDelayed {
    0% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    66% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDelayed {
    0% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    66% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Given a slight delay to allow images to load first. */
@-webkit-keyframes fadeIn {
    0% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    33% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    33% {
        -webkit-transform: translateY(-25px);
                transform: translateY(-25px);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.logo {
    width: 3.5rem;
    height: 3.5rem;
}

@media (max-width: 800px) {
    .logo {
        width: 3rem;
        height: 3rem;
    }

    .title {
        font-size: 2.5rem !important;
    }
}
.form {
  width: 700px;
}

button {
  color: rgb(233, 233, 233);
  border-color: rgb(212, 212, 212);
}

